import React from 'react';
import moment from 'moment';
import './App.scss';
import { Currencies } from './interfaces/interfaces';
import * as data from './data/usd-rates.json';
import ResultCard from './components/ResultCard/ResultCard';
import ShareCard from './components/ShareCard/ShareCard';
import logo from './assets/logo-yellow.png';

const currencies: Currencies = data;
interface IAppState {
  currencyType: string;
  currencyAmount: number;
  data: Currencies;
  result: any[];
  sortAscending: boolean;
  lastUpdatedTime: string;
}

interface IAppProps {

}

class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      data: {},
      currencyAmount: 0,
      currencyType: "USD",
      result: [],
      sortAscending: true,
      lastUpdatedTime: ''
    }
  }

  componentDidMount() {
    this.setState({data: currencies});
    const firstKey = Object.keys(currencies)[0];
    const momentLastUpdated = moment(currencies[firstKey].date);
    this.setState({lastUpdatedTime: momentLastUpdated.fromNow()})
  }
  currencyAmountChange(event: any) {
    this.setState({currencyAmount: event.target.value});
  }
  onCurrencyTypeChange(event: any) {
    this.setState({currencyType: event.target.value});
  }

  onKeyUpInput(event:any) {
    if (event.key === 'Enter') {
      this.onSumitForm();
    }
  }

  changeSortOrder() {
    this.setState({
      sortAscending: !this.state.sortAscending
    }, () => {
      this.onSumitForm();
    })
  }

  onSumitForm() {
    if(this.state.currencyAmount > 0 && this.state.currencyType) {
     this.setState({result : this.getMillionaireCurrencies(this.state.currencyAmount, this.state.currencyType, this.state.data)});
    }
  }

  getMillionaireCurrencies(netWorth: number,chosenCurrency: string, RATES: any) {
      if(!chosenCurrency) {
          chosenCurrency = 'USD';
      }

      chosenCurrency = chosenCurrency.toUpperCase();
      let chosenRateToUsd = 1

      // Normalise to USD first
      if(chosenCurrency !== 'USD') {
          for(var code in RATES) {
              var thisCurrency = RATES[code];
              if(chosenCurrency === thisCurrency.code.toUpperCase()) {
                  netWorth *= thisCurrency.inverseRate;
                  chosenRateToUsd = thisCurrency.inverseRate;
                  break;
              }
          }
      }
      var millionaireCurrencies = [];

      RATES['usd'] = {
        code: 'USD',
        name: 'US Dollar',
        rate: 1,
        inverseRate: 1
      };

      for(code in RATES) {
          var currency = RATES[code];

          const value = currency.rate * netWorth;

          if(value > 1000000) {
              currency.flag = `https://raw.githubusercontent.com/transferwise/currency-flags/master/src/rectangular-flags/${currency.code.toLowerCase()}.png`
              currency.value = `${this.formatValue(value, 2)} ${currency.code.toUpperCase()}`;
              currency.chosenRate = (chosenRateToUsd * currency.rate).toFixed(2);
              millionaireCurrencies.push(currency);
          }
      }

      return millionaireCurrencies.sort((a, b) => {
        if(this.state.sortAscending) {
          return a.rate - b.rate
        } else {
          return b.rate - a.rate
        }

      });
  }

  formatValue(num: number, digits: number) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "Q" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  render () {
    return (
      <div>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6331417177133377"
            crossOrigin="anonymous"></script>
        {/* <!-- Logo Ad --> */}
        <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-client="ca-pub-6331417177133377"
            data-ad-slot="6212394445"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        <div className="logo">
          <img src={logo} alt="Logo"/>
        </div>
        <div className="container">
          <div id="input-container">
            <p>Not everyone has a million US Dollars, but what about a million Columbian Pesos? Find out how many currencies YOU could be a millionare in. Enter your amount of money and press go!</p>
            <input id="currencyAmount" type="number" min="0" onChange={this.currencyAmountChange.bind(this)} onKeyUp={this.onKeyUpInput.bind(this)}/>
            <select name="currencyType" id="currencyType" onChange={this.onCurrencyTypeChange.bind(this)}>
            <option value="usd">USD</option>
              {Object.keys(this.state.data).map((currencyType: any, index: number) => {
                if(currencyType !== "default") {
                  return <option key={index} value={this.state.data[currencyType].code}>{this.state.data[currencyType].code}</option>
                }
                return null
              })}
            </select>
            <button id="go" onClick={this.onSumitForm.bind(this)}>
              Go
            </button>
            <p className="disclaimer">No information will leave your device when using this website. Your money is secure</p>
          </div>
          <div className="results-list row">
            {
                this.state.result.length ?
                  <ShareCard currencyCount={this.state.result.length}></ShareCard> :
                  ''
            }
            {
              (this.state.result.length) ?
                <div>
                  <span id="timeUpdated">Last updated {this.state.lastUpdatedTime}</span>
                  <button type="button" id="sort" onClick={this.changeSortOrder.bind(this)}>
                    Sort {(this.state.sortAscending) ? "Descending" : "Ascending"}
                  </button>
                </div> : ""
            }
            {this.state.result.map((result,index) => {
              return <ResultCard chosenCurrency={this.state.currencyType} index={index} currency={result}></ResultCard>
            })}
          </div>
        </div>
      </div>
    );
  }
}




export default App;
