import React from 'react'
import './ResultCard.scss'
import { Currency } from '../../interfaces/interfaces'

interface IResultCardProps {
  currency: Currency;
  chosenCurrency: string;
  index: number;
}

interface IResultCardState {
    active: boolean;
}

export default class ResultCard extends React.Component<IResultCardProps, IResultCardState> {

    constructor(props: any) {
        super(props);
        this.state = {
            active: false
        }

    }


    clickHandler() {
        this.setState({
            active: !this.state.active
        })
    }

    render() {
        return (
        <div key={this.props.index} className={(!this.state.active ? "col-md-4" : "active") + " col-sm-12 result-card-wrapper"} onClick={this.clickHandler.bind(this)}>
            <div className={"result-card col-12"}>
                <img src={this.props.currency.flag} alt={this.props.currency.code} />
                <p className="result-name">{this.props.currency.name}</p>
                <p className="result-value">{this.props.currency.value}</p>
                {(this.state.active) ?
                <div className="expanded-content">
                    1 {this.props.chosenCurrency} = {this.props.currency.chosenRate} {this.props.currency.code}
                </div> : null}
            </div>
        </div>
        )
    }
}
